<template>
    <div class="study-cards">
        <study-card v-for="(card, index) in cards"
            :icon="card.icon"
            :title="card.title"
            :url="card.url"
            :logo="card.logo"
            :key="index" />
        <observer @intersect="infiniteHandler"/>
    </div>

</template>

<script>
    import StudyService from "../../services/StudyService";
    import StudyCard from "./StudyCard";
    import Observer from "../app/Observer";

    export default {
        name: "StudyCards",
        components: {
            StudyCard,
            Observer
        },
        data() {
            return {
                cards: [],
                scrollActive: true,
                offset: 0,
                limit: 40
            }
        },
        methods: {
            async infiniteHandler()
            {
                if(!this.scrollActive) {
                    return;
                }

                let data = await StudyService.getCategories(this.limit, this.offset);

                if (data.length) {
                    let cards = this.parseCards(data);

                    this.offset += this.limit;
                    this.totalCount = data.totalCount;
                    this.cards = [...this.cards, ...cards];
                    this.scrollActive = data.length === this.limit;
                } else {
                    this.scrollActive = false
                }
            },
            parseCards(data)
            {
                return data.map(item => {
                    return {
                        url: '/study/' + item.id,
                        title: item.title,
                        icon: item.photo
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .study-cards {
        display: flex;
        flex-flow: row wrap;
    }

</style>
