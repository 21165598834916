<template>
    <div class="wrapper">
        <div class="mobile-panel">
            <CommonBlock
                title="Навчання"
                :visible-back-btn="true"
                :breadcrumbs="breadcrumbs"
                :cancelBackBtnEventPassed="true"
                @clickBack="clickBack"
            >
            </CommonBlock>
        </div>
        
        <section class="study">
            <header class="header">
                <router-link to="/" class="header-logo">
                    <img src="../assets/images/logo-header.svg" alt="logo"/>
                </router-link>
            </header>

            <ul class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="breadcrumb-link" to="/" @click="openMenu"
                    >Головна
                    </router-link
                    >
                </li>
                <li class="breadcrumb-item">Навчання</li>
            </ul>

            <div class="page">
                <div class="page-top">
                    <h3 class="page-title">Навчання</h3>
                    <!-- <div class="study-filter">
                      <input type="text" placeholder="Study" />
                      <input type="text" placeholder="Study" />
                      <button>Study</button>
                    </div> -->
                </div>
                <study-cards/>
               
            </div>
        </section>
    </div>
</template>
<script>
import CommonBlock from "@/components/CommonBlock";
import StudyCards from "../components/study/StudyCards";

export default {
    components: {
        CommonBlock,
        StudyCards,
    },
    methods: {
        openMenu() {
        }
    }
}
</script>

<style scoped lang="less">
    .mobile-panel {
        display: none;       
        @media screen and (max-width: 950px) {
            display: block;
        }
    }
    .wrapper {
        max-width: 1920px;
        /* max-width: 1780px; */
        padding-right: 60px;
        padding-left: 80px;
    }

    .header {
        width: 100%;
        padding-top: 80px;
    }

    .header-logo {
        width: 253px;
        height: 51px;
        margin-bottom: 22px;
        display: inline-block;
    }


    /* breadcrumb */
    .breadcrumb {
        list-style: none;
        margin-bottom: 45px;
    }

    .breadcrumb-item {
        display: inline;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #1a2744;
        margin-right: 20px;
    }

    .breadcrumb-link {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #ffc900;
        text-decoration: none;
        position: relative;

        &::after {
            position: absolute;
            content: '';
            width: 1px;
            height: 15px;
            background: #1a2744;
            bottom: 1px;
            right: -11px;
        }
    }


    /* Study page */

    .page {
    }

    .page-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 53px;
    }

    .page-title {
        font-weight: bold;
        font-size: 60px;
        line-height: 80px;
        letter-spacing: -0.42px;
        color: #1a2744;
    }

    .study {
        position: relative;
    }

    .study-filter input {
        width: 260px;
        height: 56px;
        border: 1px solid #ffc900;
        border-radius: 15px;
        padding-left: 20px;
        margin-right: 20px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #1a2744;
        outline: none;
    }

    .study-filter input::placeholder {
        color: #1a2744;
    }

    .study-filter button {
        width: 260px;
        height: 56px;
        background: #ffc900;
        border-radius: 15px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #1a2744;
        transition: all 0.3s ease-in;
        box-shadow: 0px 14px 14px rgba(249, 198, 35, 0.35);
    }

    .study-filter button:hover {
        box-shadow: 0px 24px 24px rgba(249, 198, 35, 0.35);
        transform: scale(1.01);
    }


    @media screen and (max-width: 1440px) {
        .wrapper {
            padding-right: 50px;
            padding-left: 50px;
        }
    }

     @media screen and (max-width: 950px) {
        .header,
        .breadcrumb,
        .page-title,
        .page-top {
            display: none;
        }
        .wrapper {
            padding-left: 0;
            padding-right: 0;
        }
     }

    @media screen and (max-width: 480px) {
        .page-title {
            font-size: 45px;
            line-height: 60px;
            margin-bottom: 20px;
        }
        .study-filter {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .study-filter input {
            margin-right: 0px;
            margin-bottom: 20px;
            width: 100%;
        }

        .study-filter button {
            width: 100%;
        }

        .page-top {
            margin-bottom: 30px;
        }

        .breadcrumb {
            margin-bottom: 15px;
        }
        .study-card__container {
            margin: 0;
        }
        .page {
            padding: 10px;
        }
    }
</style>
