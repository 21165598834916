<template>
    <router-link
        :to="url"
        :key="index"
        class="study-card__container"
    >
        <span class="study-cards_img">
            <img :src="icon"/>
        </span>
        <!--<span class="study-card__logo">
              <img :src="logo"/>
        </span>-->
        <span class="study-card__info">
            <h5 class="study-card__title">
                {{ title }}
            </h5>
        </span>
    </router-link>

</template>

<script>
    export default {
        name: "StudyCard",
        props: {
            icon: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            url: {
                type: String,
                required: true
            },
            logo: {
                type: String,
                required: false
            }
        }
    }
</script>

<style scoped lang="less">
    .study-card__container {
        /*max-width: 580px;*/
        width: 100%;
        height: 512px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        display: block;
        padding: 0.5em;
        @media screen and (max-width: 768px) {
            height: 282px;         
        }
    }

    .study-cards_img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all 1s;
        display: inline-block;
        position: relative;
        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #1a2744;
            opacity: .6;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .study-card__info {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        padding: 20px;
        max-width: 417px;
    }

    .study-cards_img:hover {
        transform: scale(1.2);
    }

    .study-card__logo {
        position: absolute;
        top: 20px;
        left: 40px;
        object-fit: cover;
    }

    .study-card__container:first-child .study-card__logo {
        top: 0;
    }

    .study-card__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.168px;
        color: #fff;
        @media screen and (max-width: 480px) {
          font-size: 18px;
            line-height: 24px;
        }
    }

    .study-card:hover {
        background-size: 150%;
        -webkit-transition: all 0.3s ease-in;
        transition: all 0.7s ease-in;
    }

    .study-card__category {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.098px;
        text-transform: uppercase;
        color: #fff;
    }

    .study-card__icon {
        width: 40px;
        height: 40px;
        background: #ffc900;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }
    @media all and (max-width: 1440px) {
        .study-card__container {
            height: auto;
        }
    }
    @media all and (min-width: 992px) {
        .study-card__container {
            width: calc(100% / 12 * 4 );
        }
    }
    @media all and (max-width: 991px) {
        .study-card__container {
            width: calc(100% / 12 * 6 );
            height: 320px;
        }
    }
    @media all and (max-width: 600px) {
        .study-card__container {
            width: 100%;
        }
    }

</style>
